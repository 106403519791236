import React from 'react';
import Layout from '../components/Layout';


function blog(){
    return (
        <Layout>
            Blog in Progress
    </Layout>)
}

export default blog;